<template>
  <div class="p-12 text-center">
    <p class="text-lg font-medium">Not Found</p>
    <p>Please contact customer support if you weren't expecting this page.</p>
  </div>
</template>

<script>
export default {
  name: '404',

  mounted() {
    // eslint-disable-next-line
    _chatlio.hide()
  }
}
</script>

<style lang="scss" scoped></style>
